import { enableMapSet } from 'immer';

import { commitId } from './build-info';
import './index.scss';

enableMapSet();

console.log('Enlight Application Version');
console.log('commitId:', commitId || 'local');

import('./initialize');
